// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendar-js": () => import("./../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-perform-js": () => import("./../src/pages/perform.js" /* webpackChunkName: "component---src-pages-perform-js" */),
  "component---src-pages-teach-js": () => import("./../src/pages/teach.js" /* webpackChunkName: "component---src-pages-teach-js" */)
}

